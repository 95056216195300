<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>出库单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addWarehouseenter" :rules="addWarehouseenterRules"
               ref="addWarehouseenterRef" label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="仓库" prop="warehouselist">
              <el-cascader
                  :options="TreeList" v-model="addWarehouseenter.warehouselist"
                  :props="Treeprops" @change="getcheckList"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col>
            <el-button @click="add" type="primary" :disabled="adddisabled">添加商品</el-button>
            <el-button @click="addGoodssale" type="primary" >选择销售订单</el-button>
            <el-button @click="addpurchasereturn" type="primary" >选择采购退货订单</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0px">
          <el-table :data="addWarehouseenter.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name">
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <el-select v-model="scope.row.gad_id" placeholder="请选择日期" v-if="scope.row.goodsattrdate.length!=0"
                           clearable class="p_purchase_ids">
                  <el-option
                      v-for="item in scope.row.goodsattrdate"
                      :key="item.gad_id"
                      :label="`${item.gad_start_date}~${item.gad_end_date}`"
                      :value="item.gad_id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间~结束时间" width="300px">-->
<!--              <template slot-scope="scope" v-if="scope.row.goodsattrdate.length==0">-->
<!--                <el-select v-model="scope.row.gad_id" placeholder="请选择"-->
<!--                           clearable class="p_purchase_id">-->
<!--                  <el-option-->
<!--                      v-for="item in scope.row.goodsattrdate"-->
<!--                      :key="item.gad_id"-->
<!--                      :label="`${item.gad_start_date}~${item.gad_end_date}`"-->
<!--                      :value="item.gad_id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="库存" prop="ga_stock" ></el-table-column>
            <el-table-column label="出库数量" prop="prop">
              <template slot-scope="scope">
                <el-input v-model="scope.row.woi_number" ></el-input>
              </template>
            </el-table-column>
<!--            <el-table-column label="删除">-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-form-item>
        <el-form-item label="经办人：" prop="wo_agent">
          <el-select v-model="addWarehouseenter.wo_agent" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户：" prop="we_agent">
          <el-select v-model="addWarehouseenter.c_id" placeholder="请选择"
                     clearable class="p_purchase_id" filterable>
            <el-option
                v-for="item in supplierList"
                :key="item.c_id"
                :label="item.c_name"
                :value="item.c_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库时间：" prop="wo_date" >
          <el-date-picker v-model="addWarehouseenter.wo_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出库备注：" prop="wo_remark" >
          <el-input v-model="addWarehouseenter.wo_remark" class="p_remark"></el-input>
        </el-form-item>

        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>

    <el-dialog  title="选择商品" :visible.sync="addGoodsDialogVisible"
                width="70%">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="querInfo.g_name" clearable @clear="getGoodsList">
            <el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable placeholder="请选择商品分类"
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>
      <el-table :data="Goodslist"  stripe  @select-all="changeFun" @select="asd" ref="docTable">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
            <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name" width="120px"></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" width="120px"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择销售订单" :visible.sync="addGoodssaleDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="GoodssaleList" ref="singleTable" stripe highlight-current-row  @current-change="handlePCurrentChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index" width="50px"></el-table-column>
        <el-table-column label="订单编号" prop="s_code" width="190px" ></el-table-column>
        <el-table-column label="客户名称" prop="c_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="联系人" prop="cc_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="收货人" prop="s_consignee" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="送货人" prop="s_deliverer_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="销售员" prop="s_sale_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="销售时间" prop="s_date" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品金额" prop="s_price" width="100px" show-overflow-tooltip></el-table-column>

      </el-table>
      <el-pagination
          @size-change="gshandleSizeChange"
          @current-change="gshandleCurrentChange"
          :current-page="gsquerInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="gsquerInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="gstotal">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodssaleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPwtergo()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择采购退货订单" :visible.sync="addpurchaseDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="purchaselist" ref="singleTable" stripe highlight-current-row  @current-change="handlepurshaseChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="pr_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购订单号" prop="p_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="退货员" prop="pr_purchase_name" width="80px"></el-table-column>
        <el-table-column label="采购退货时间" prop="pr_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货金额" prop="pr_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货其他金额" prop="pr_other_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货总计" width="100px">
          <template slot-scope="scope">
            <div style="color: red;font-weight: bold;">{{scope.row.pr_total_price}}</div>
          </template>
        </el-table-column>
        <el-table-column label="退款状态" prop="pr_payment_status_name" width="80px"></el-table-column>
      </el-table>
      <el-pagination
          @size-change="purshasehandleSizeChange"
          @current-change="purshasehandleCurrentChange"
          :current-page="purshasequerInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="purshasequerInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="purshasetotal">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addpurchaseDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addreturnpurchasego">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        // 's_id':''
      },
      total:0,
      gsquerInfo: {
        page: 1,
        limit: 10,
        s_warehouse_status:"1,2"
      },
      gstotal:0,
      //添加表单的form表单
      addWarehouseenter:{
        wo_type:2,
        warehouselist:[],
        goodslist:[],
        wo_date:this.$store.state.daydate,
        wo_agent:this.$store.state.m_id
      },
      //商品
      Goodslist:[],
      //销售列表
      GoodssaleList:[],
      //员工列表
      userlist:[],
      //供应商列表
      TreeList:[],
      //客户列表
      supplierList:[],
      Treeprops:{
        // multiple: true,
        // checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},
        // id:{'s_id':''},
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      //控制选择销售单的显示或隐藏
      addGoodssaleDialogVisible:false,
      checkBoxData: [],
      //
      adddisabled:true,
      addWarehouseenterRules: {
        warehouselist: [
          {required: true, message: '请选择仓库', trigger: 'change'}
        ],
        wo_agent:[
          {required: true, message: '请选择经办人', trigger: 'change'}
        ],
        wo_date:[
          {required: true, message: '请选择出库时间', trigger: 'change'}
        ],
        // wo_remark:[
        //   {required: true, message: '请输入出库备注', trigger: 'blur'}
        // ]
      },
      //商品分类list
      categorieslList:[],
      //选中的父级分类的ID数组
      selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      addpurchaseDialogVisible:false,
      purchaselist:[],
      purshasequerInfo: {
        page: 1,
        limit: 10,
        // 's_id':''
      },
      purshasetotal:0,

    }
  },
  created() {
    this.showSetTreeListDialog()
    this.getuserList()
    this.getsupplierList()
    this.getcateList()
  },
  methods: {
    addpurchasereturn(){
      this.getPurchaselist()
      this.addpurchaseDialogVisible=true
    },
    handlepurshaseChange(val){
      this.purshaseid=val.pr_id
    },
    async getPurchaselist() {
      const {data: res} = await this.$http.get('purchasereturn/getList',
          {params: this.purshasequerInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.purchaselist = res.data.data
      this.purshasetotal = res.data.total
    },
    //获取商品分类
    async getcateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.categorieslList = res.data
    },
    //获取销售订单
    async getgoodssaleList(){
      const {data:res} = await this.$http.get('sale/getList',
          {params:this.gsquerInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.GoodssaleList=res.data.data
      this.gstotal=res.data.total
    },
    //获取仓库数据
    async showSetTreeListDialog() {
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    //获取商品数据
    async getGoodsList(){
      const {data:res} = await this.$http.get('goods/getList',
          {params:this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.Goodslist=res.data.data
      this.total=res.data.total
      setTimeout(() => {
        this.pickUp() //需要默认勾选的数据
      }, 10)
    },
    //获取客户列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('customer/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },
    //监听pagesize改变
    gshandleSizeChange(newSize) {
      this.gsquerInfo.limit = newSize
      this.getgoodssaleList()
    },
    //监听pagenum 改变
    gshandleCurrentChange(newPage) {
      this.gsquerInfo.page = newPage
      this.getgoodssaleList()
    },
    //监听pagesize改变
    purshasehandleSizeChange(newSize) {
      this.purshasequerInfo.limit = newSize
      this.getGoodsList()
    },
    //监听pagenum 改变
    purshasehandleCurrentChange(newPage) {
      this.purshasequerInfo.page = newPage
      this.getGoodsList()
    },
    setCurrent(row){
      this.is_p_num=false
      this.is_p_wnum=false
      this.addWarehouseenter.goodslist=[]
      this.$refs.singleTable.setCurrentRow(row);
    },
    asd(selection, row) {
      let is_exists = true;
      this.addWarehouseenter.goodslist.forEach((item,index)=>{
        if (this.addWarehouseenter.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addWarehouseenter.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addWarehouseenter.goodslist.push(row)
        is_exists = true;
      }
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addWarehouseenter.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // checkboxT(row, rowIndex){
    //   this.Goodslist.forEach((item) => {
    //     this.addWarehouseenter.goodslist.forEach((val) => {
    //       if(val.ga_id === item.ga_id){
    //         return false;//禁用
    //       }else{
    //         return true;//不禁用
    //       }
    //     })
    //   })
    //
    //
    // },
    // 默认选择中的行
    toggleSelection(rows) {
      console.log(rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.docTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.docTable.clearSelection()
      }
    },
    //供应商选项框发生变化时的回调函数
    getcheckList(){
      if (this.addWarehouseenter.warehouselist == []){
        this.addWarehouseenter.goodslist=[]
        this.adddisabled=true
        return true
      }else{
        this.addWarehouseenter.w_id=this.addWarehouseenter.warehouselist[0]
        this.addWarehouseenter.wp_id=this.addWarehouseenter.warehouselist[1]
        this.querInfo.w_id=this.addWarehouseenter.warehouselist[0]
        this.querInfo.wp_id=this.addWarehouseenter.warehouselist[1]
        this.addWarehouseenter.goodslist=[]
        // this.id.s_id=this.ss_id
        this.getGoodsList(this.querInfo)
        this.adddisabled=false
      }
    },
    add(){
      this.addGoodsDialogVisible=true

    },
    addGoodssale(){
      this.getgoodssaleList()
      this.addGoodssaleDialogVisible=true
    },
    handleSelectionChange (selection) {
      console.log(selection)
    },
    //单选采购订单
    handlePCurrentChange(val) {
      this.Goodssaleid=val.s_id
    },
    //跳转采购入库
    addPwtergo(){
      this.$router.push({path: "goodssale/addgoodssaleout", query: {editid: this.Goodssaleid}})
    },
    addreturnpurchasego(){
      this.$router.push({path: "/purchasereturn/returnpurchasewarehouseout", query: {editid: this.purshaseid}})
    },
    //提交form表单
    async addupPurchase(){
      this.$refs.addWarehouseenterRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('warehouseout/add', this.addWarehouseenter)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addWarehouseenter.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addWarehouseenter.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    delGoodsList(id){
      this.addWarehouseenter.goodslist.forEach((item,index)=>{
        if (item.ga_id===id){
          this.addWarehouseenter.goodslist.splice(index,1)
        }
        // this.addWarehouseenter.goodslist.splice(index,1)
      })
    },
    //商品分类
    parentCateChanged() {
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      console.log(this.querInfo.g_cid)
      this.getGoodsList()
    },
  },


}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_other_price{
  width: 200px;
}
.p_date,.p_remark,.el-select,.el-cascader{
  width: 250px;

}

</style>
